import React from 'react';

import logoDegreed from '../../images/degreed.png';

const data = {
  'Conhecimento do Usuário/Cliente': {
    description: <>Conhece os seus clientes chave</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/e9jl2r738w?path=os-3-horizontes-alvo">
            Faça a SEÇÃO 1 e 2 da Trilha "Os 3 horizontes alvo" da Gestão de
            Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/dp63wqxnp7#/pathway">
            Faça a SEÇÃO 3 da Trilha "Product Discovery"
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/49e2v5w285?path=tend-ncias-na-gest-o-de-produtos-digitais">
            Faça a Seção 1 da Trilha Tendências na Gestão de Produtos Digitais
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/08g4q5kxpw?path=m-tricas-e-indicadores-com-vis-o-cliente">
            Complete a Trilha Métricas e indicadores visão cliente
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/o97mme0vpn#/pathway">
            Faça a Seção 2 da Trilha Personas e Jornada de Decisão dos Clientes
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
        <li>Leia o Livro “Sprint” de Jake Knapp</li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/dp63wqxnp7?path=product-discovery">
            Complete a Trilha Product Discovery
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
      </>,
      <>
        <li>
          Parabéns! Você possui conhecimentos suficientes para ser uma
          referência no tema. Mentore/desenvolva esta competência em outras
          pessoas do seu time
        </li>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Conhecimento da Indústria/Setor e do Domínio de Negócio': {
    description: <>Conhece o setor onde o seu produto está inserido</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/e9jl2r738w?path=os-3-horizontes-alvo">
            Faça a SEÇÃO 3 da Trilha “Os 3 horizontes alvo” da Gestão de
            Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/e9jl2r738w?path=os-3-horizontes-alvo">
            Participe dos papos de produto. Caso queira ver as sessões
            anteriores, acesse o Papo de Produtos – Hub de Gestão de Produtos
            Digitais
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/08q71oer9r?path=clube-do-livro-comunidades">
            Leia o Livro “Gestão de Produtos”, de Joaquim Torres
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/e9j7l1gd8w#/pathway">
            Faça a SEÇÃO 2 da Trilha “Entendendo o Mercado Financeiro”
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/08q71oer9r?path=clube-do-livro-comunidades">
            Complete a Trilha Tendências na Gestão de Produtos Digitais
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Elabore um case em que esta competência se destacou na sua entrega
          final. Compartilhe-o com o time, na iox, ou até no Linkedin, caso
          queira
        </li>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
        <li>
          Realize a mentoria de um ou mais colaboradores no desenvolvimento
          desta habilidade.
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Conhecimento do Negócio e da Empresa': {
    description: (
      <>Os stakeholders o enxergam como guardião de preocupações e restrições</>
    ),
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/e9jl2r738w?path=os-3-horizontes-alvo">
            Faça a SEÇÃO 4 da Trilha “Os 3 horizontes alvo” da Gestão de
            Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Desenhe o lean canvas de seu squad e o business model canvas de sua
          comunidade.
        </li>
        <li>
          <a href="https://degreed.com/pathway/e9j7l1gd8w#/pathway">
            Faça as SEÇÃO 1 e 2 da Trilha “Entendendo o Mercado Financeiro”
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
        <li>Leia o livro “A startup enxuta”, de Eric Ries</li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
        <li>
          Realize a mentoria de um ou mais colaboradores no desenvolvimento
          desta habilidade.
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Conhecimento e uso Dados': {
    description: <>Possui expertise em ferramentas de dados</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/k9w1yqogp4?path=mindset-datadriven">
            Complete a Trilha Mindset Data Driven
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/courses/an-lise-de-dados-com-tabela-din-mica-excel---curso-completo?d=18992313&view=true&contentSource=Search">
            Realize o curso Análise de Dados com Tabela dinâmica Excel
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/08g4q5kxpw#/pathway">
            Complete a Trilha Métricas e Indicadores com visão cliente
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/59yljoy58o?path=digital-analytics---ferramentas-de-web-analytics">
            Complete a Trilha Digital Analytics | Ferramentas de Web Analytics
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/3pm10q1g9n?path=curso-b-sico-de-sql">
            Complete a Trilha Curso básico SQL
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Aplique Data Science na rotina do seu time. Exemplo: fazer Predição de
          Churn
        </li>
        <li>
          Elabore um case em que esta competência se destacou na sua entrega
          final. Compartilhe-o com o time, na iox, ou até no Linkedin, caso
          queira
        </li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Conhecimento Operacional do Produto': {
    description: (
      <>
        Conhece o funcionamento do produto e é capaz de responder qualquer
        dúvida
      </>
    ),
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/08gzdrkx9w?path=you-build-it--you-run-it-">
            Complete a Trilha You build it you run it
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/k9ogekgdpx#/pathway">
            Complete a Trilha Otimização e Manutenção de Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/courses/an-lise-de-dados-com-tabela-din-mica-excel---curso-completo?d=18992313&view=true&contentSource=Search">
            Curso Análise de Dados com Tabela dinâmica Excel
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          Sugira melhorias na trilha “You Build it You Run it” através do espaço
          de feedback na própria trilha
        </li>
      </>,
      <>
        <li>
          Identifique quais são as dívidas técnicas do seu produto e implemente
          um plano para reduzi-las.
        </li>
        <li>
          Elabore um case em que esta competência se destacou na sua entrega
          final. Compartilhe-o com o time, na iox, ou até no Linkedin, caso
          queira
        </li>
      </>,
      <>
        <li>
          Parabéns! Você possui conhecimentos suficientes para ser uma
          referência no tema. Mentore/desenvolva esta competência em outras
          pessoas do seu time
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Habilidade de Colaboração em Equipe': {
    description: <>Envolve e aproveita todos do time desde o início</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/3pm3gr6g8n?path=mais-colabora--o--menos-competi--o">
            Complete a Trilha Mais colaboração, menos competição
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/k9wowex3p4?path=agir-diferente">
            Faça a Seção 3 da Trilha Agir diferente
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/08q71vy59r?path=potencializando-times-remotos">
            Faça a Seção 5 da Trilha Potencializando times remotos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/e9km3eg18o#/pathway">
            Faça a Seção 1 e 2 da Trilha A cultura da experimentação
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>Complete a Trilha Comunicação Não Violenta</li>
        <li>
          <a href="https://degreed.com/pathway/k9o05lrq8x?orgsso=itau">
            Trilha Negociação
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/o84k2767p4?orgsso=itau">
            Trilha Comunicação Estratégica
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
        <li>
          Escreva um artigo sobre o tema no LinkedIn, na iox ou outra
          plataforma.
        </li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
        <li>
          Realize a mentoria de um ou mais colaboradores no desenvolvimento
          desta habilidade
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Habilidade de gestão de Stakeholders': {
    description: <></>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/o84k2767p4?path=comunica--o-estrat-gica">
            Trilha Comunicação Estratégica
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/o84k2767p4?path=comunica--o-estrat-gica">
            Trilha Comunicação Estratégica
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/mpl2g5zv8d?path=comunica--o-n-o-violenta">
            Trilha Comunicação Não Violenta
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>Conduza um wsp/palestra sobre o tema</li>
        <li>
          Escreva um artigo sobre o tema no LinkedIn, Degreed, ou outra
          plataforma.
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/mpl2g5zv8d?path=comunica--o-n-o-violenta">
            Trilha Comunicação Não Violenta
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>Conduza um wsp/palestra sobre o tema</li>
        <li>
          Escreva um artigo sobre o tema no LinkedIn, Degreed, ou outra
          plataforma.
        </li>
      </>,
      <>
        <li>
          Participe de um evento externo sobre o tema (podcast relevante no
          mercado, palestra, wskp, etc)
        </li>
      </>,
    ],
  },
  'Habilidades de Evangelização': {
    description: (
      <>Compartilha a estratégia e motiva todos a contribuirem com a evolução</>
    ),
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/o84mv1wo94?path=influ-ncia-sem-autoridade">
            Complete a Trilha influência sem autoridade
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/v9r7nmew9j?path=como-criar-relacionamentos-fortes-e-influenciar-positivamente-a-equipe">
            Complete a Trilha Como criar relacionamentos fortes e influenciar
            positivamente a equipe
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/o84k2767p4?path=comunica--o-estrat-gica">
            Complete a Trilha Comunicação Estratégica
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/49e2v5w285?path=tend-ncias-na-gest-o-de-produtos-digitais">
            Faça as Seções de 3 a 7 da Trilha Tendências na Gestão de Produtos
            Digitais
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/08q71oer9r?path=clube-do-livro-comunidades">
            Leia o livro “Empowered”, de Marty Cagan
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          Chegou a hora de começar a compartilhar este conhecimento. Conduza
          pequenos workshops/palestras sobre o tema junto ao seu time.
        </li>
      </>,
      <>
        <li>
          Parabéns! Você possui conhecimentos suficientes para ser uma
          referência no tema. Mentore/desenvolva esta competência em outras
          pessoas do seu time
        </li>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Habilidades de Liderança': {
    description: <>É reconhecido como a principal liderança do produto</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/49e5nj4185?path=lideran-a--gil">
            Complete a Trilha Liderança Ágil
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/o84k2767p4?path=comunica--o-estrat-gica">
            Complete a Trilha Comunicação Estratégica
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/o84kedx7p4#/pathway">
            Faça a Seção 5 - Trilha Quem faz a gestão de um produto?
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/jp1r2j2epv?orgsso=itau">
          Trilha Liderando a Transformação
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/plan/1726677#/">
            Complete o Plano Líderes do Futuro
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/08q71oer9r?path=clube-do-livro-comunidades">
            Leia o livro “Empowered”, de Marty Cagan
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/49ez5vn195?path=lideran-a-exponencial">
            Complete a Trilha Liderança Exponencial
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/08q71oer9r?path=clube-do-livro-comunidades">
            Leia o livro “A velocidade da Confiança”, de Stephen Covey
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        </>,
      <>
        <li>
          Parabéns! Você possui conhecimentos suficientes para ser uma
          referência no tema. Mentore/desenvolva esta competência em outras
          pessoas do seu time
        </li>
        </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Processo de Desenvolvimento de Produto': {
    description: (
      <>Possui conhecimento sobre a etapa de desenvolvimento do produto</>
    ),
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/e9jl2r738w?path=os-3-horizontes-alvo">
            Faça a SEÇÃO 5 da Trilha “Os 3 horizontes alvo” da Gestão de
            Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/k9ogekgdpx#/pathway">
            Complete a Trilha Otimização e Manutenção de Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/k9w1yqogp4?path=mindset-datadriven">
            Complete a Trilha Mindset Data Driven
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/plan/1969933#/">
            Complete as trilhas do Pilar Processos do plano de Gestão de
            Produtos
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
      </>,
      <>
        <li>
          Escreva um artigo sobre o tema no LinkedIn, na iox ou outra
          plataforma.
        </li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
        <li>
          Realize a mentoria de um ou mais colaboradores no desenvolvimento
          desta habilidade
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Técnicas de Otimização de Produto': {
    description: (
      <>Utiliza técnicas de otimização com o produto já em produção</>
    ),
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/k9ogekgdpx?path=otimiza--o-e-manuten--o-de-produtos">
            Complete a Trilha de otimização de Produto
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/m90deoeg86?path=produtividade--gil">
            Faça a Seção 2 da Trilha Produtividade Ágil
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
        <li>Leia o livro “Atravessando o abismo” de Geoffrey Moore</li>
      </>,
      <>
        <li>
          Desenvolva uma estratégia de PGL (Product Led Growth) para escalar seu
          produto.
        </li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
        <li>
          Realize a mentoria de um ou mais colaboradores no desenvolvimento
          desta habilidade
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Técnicas de Product Delivery': {
    description: <>Envolve todas as skills necessárias nesta etapa</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/m904kv5rp6?path=product-delivery">
            Complete a Trilha Product Delivery
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/08qmqmj4pr#/pathway">
            Complete a Trilha Objective Key Results (OKR)
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          <a href="https://degreed.com/pathway/1pn62nnwpn#/pathway">
            Complete a Trilha Entendendo o Scrum
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          Sugira melhorias na trilha de desenvolvimento desta competência
          através do espaço de feedback na própria trilha
        </li>
      </>,
      <>
        <li>
          Escreva um artigo sobre Dual Track: Continuous Discovery & Delivery no
          LinkedIn, na iox ou outra plataforma.
        </li>
      </>,
      <>
        <li>
          Parabéns! Você possui conhecimentos suficientes para ser uma
          referência no tema. Mentore/desenvolva esta competência em outras
          pessoas do seu time
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
  'Técnicas de Product Discovery': {
    description: <>Conhece os riscos, resolve problemas e foca no resultado</>,
    items: [
      <>
        <li>
          <a href="https://degreed.com/pathway/dp63wqxnp7?path=product-discovery">
            Complete a Trilha Product Discovery
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
      </>,
      <>
        <li>
          <a href="https://degreed.com/pathway/08q71oer9r?path=clube-do-livro-comunidades">
            Leia o livro “Inspired”, de Marty Cagan
          </a>
          <img src={logoDegreed} class="logo degreed" alt="Degreed" />
        </li>
        <li>
          Evolua um produto já existente à partir da aplicação de uma técnica de
          Discovery
        </li>
      </>,
      <>
        <li>Leia o livro “Continuos Discovery Habits” de Teresa Torres</li>
        <li>
          Escreva um artigo sobre o tema no LinkedIn, na iox ou outra
          plataforma.
        </li>
      </>,
      <>
        <li>
          Parabéns Você possui conhecimentos suficientes para compartilhar em
          grupo! Conduza workshops/palestras sobre o tema
        </li>
        <li>
          Realize a mentoria de um ou mais colaboradores no desenvolvimento
          desta habilidade
        </li>
      </>,
      <>
        <li>
          Busque oportunidades para multiplicar conhecimento em eventos externos
          sobre o tema. Exemplo: podcast relevante no mercado, palestra,
          workshop, etc)
        </li>
      </>,
    ],
  },
};

export function getData(name, level) {
  return {
    description: data[name].description,
    items: data[name].items[level],
  };
}
