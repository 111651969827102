import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Router } from 'react-router-dom';
import GlobalProvider from './contexts';
import history from './services/history';
/* istanbul ignore file */

ReactDOM.render(
  <Router history={history}>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
