import React from 'react';
import './Alternative.css';

const Alternative = ({ alternative, selected, onSelected }) => {
  const classes = () => {
    const classes = ['alternative-container'];

    if (selected) {
      classes.push('selected');
    }

    return classes.join(' ');
  };

  const handleClick = () => {
    onSelected(alternative.reference);
    classes(alternative.corect);
  };

  return (
    <li
      key={alternative.reference}
      id={alternative.reference}
      onClick={handleClick}
      className={classes()}
    >
      <input
        className="checkBox"
        name="alternatives"
        defaultChecked={selected}
        readOnly={true}
      />
      <p className="answers">{alternative.text}</p>
    </li>
  );
};

export default Alternative;
