import { getApiEndpoint } from '../shared/endpoint';

const AssessmentApi = token => {
  const endpoint = getApiEndpoint();

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  // const api = axios.create({
  //   baseURL: endpoint,
  //   headers: requestOptions.headers,
  // });

  const availableAssessments = async () => {
    return fetch(`${endpoint}/assessments/available`, {
      method: 'GET',
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const createAssessment = async templateName => {
    return fetch(`${endpoint}/assessments`, {
      method: 'POST',
      body: JSON.stringify({ templateName }),
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const listAssessments = async () => {
    return fetch(`${endpoint}/assessments`, {
      method: 'GET',
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const getAssessment = async assessmentId => {
    return fetch(`${endpoint}/assessments/${assessmentId}`, {
      method: 'GET',
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const startAssessment = async (assessmentId, replyTo) => {
    return fetch(`${endpoint}/assessments/${assessmentId}/start`, {
      method: 'POST',
      body: JSON.stringify({ replyTo }),
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const finishAssessment = async assessmentId => {
    return fetch(`${endpoint}/assessments/${assessmentId}/finish`, {
      method: 'POST',
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const startQuestion = async (assessmentId, questionId) => {
    return fetch(
      `${endpoint}/assessments/${assessmentId}/questions/${questionId}/start`,
      {
        method: 'PUT',
        body: JSON.stringify({ now: new Date() }),
        ...requestOptions,
      }
    ).then(resp => resp.json());
  };

  const answerQuestion = async (assessmentId, questionId, answer) => {
    return fetch(
      `${endpoint}/assessments/${assessmentId}/questions/${questionId}`,
      {
        method: 'PUT',
        body: JSON.stringify({ reference: answer, now: new Date() }),
        ...requestOptions,
      }
    ).then(resp => resp.json());
  };

  return {
    availableAssessments,
    createAssessment,
    listAssessments,
    getAssessment,
    startAssessment,
    finishAssessment,
    startQuestion,
    answerQuestion,
  };
};

export default AssessmentApi;
