import React, { useContext, useCallback } from 'react';
// import Cookies from 'js-cookie';
// import Loading from '../components/Loading';
// import SessionActions from './SessionActions';
import { AuthContext } from '../contexts/AuthContext';
import { Formik } from 'formik';
import { Form, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import Button from '../components/Button';

import {
  isNumeric,
  DEFAULT_ROLES,
  hasValidDomain,
  FormattedDomains,
  hasFunctionalDigits,
} from './../profile/UserUtils';

// const { getUser, getToken } = SessionActions(Cookies);

// const getCode = () => {
//   const params = {};
//   window.location.search
//     .replace('?', '')
//     .split('&')
//     .forEach(param => {
//       const [key, value] = param.split('=');
//       params[key] = value;
//       return param;
//     });
//   return params['code'];
// };

//const handleSignUp = async () => {
//const code = getCode();
//if (code) {
//await getToken(code);
//}
//return getUser();
//};

// const communityList = [
//   'AINDA NÃO FAÇO PARTE DE UMA COMUNIDADE',
//   'ANTECIPAÇÃO DE RECEBÍVEIS E DOMICILIAÇÃO',
//   'ANTECIPAÇÃO E CRÉDITO (REDE)',
//   'ARQUITETURA CORPORATIVA',
//   'CÂMBIO E UNIDADES INTERNACIONAIS',
//   'CANAIS (REDE)',
//   'CANAIS',
//   'CARTÃO BRANCO',
//   'CARTÕES',
//   'CASH MANAGEMENT',
//   'CLOUD',
//   'CONSÓRCIO',
//   'CONTRATAR (REDE)',
//   'CRÉDITO IMOBILIÁRIO',
//   'CRÉDITO PF',
//   'CRÉDITO PJ',
//   'DADOS',
//   'EMPRESAS',
//   'GARANTIAS',
//   'INVESTMENT SERVICES',
//   'ITAU CORRESPONDENTE',
//   'ITI',
//   'JORNADA CLIENTE VAREJO (REDE)',
//   'MAQUININHAS (REDE)',
//   'OUTRA',
//   'PAGAMENTOS',
//   'PESSOAS',
//   'PREVENÇÃO A FRAUDES',
//   'PRICING E COBRANÇA (REDE)',
//   'PRODUTOS ATIVOS PJ',
//   'PRODUTOS E SERVIÇOS DE TESOURARIA',
//   'RECEBER (REDE)',
//   'RECUPERAÇÃO DE CRÉDITO',
//   'SOLUÇÕES DE INVESTIMENTOS',
//   'VEÍCULOS',
//   'VENDER (REDE)',
//   'VENDER DIGITALMENTE (REDE)',
//   'VENDER FISICAMENTE (REDE)',
// ];

// const areaList = [
//   'Não encontrei minha área',
//   'SUP IC DE OPERACOES',
//   'SUP ITAUCARD NCC CO-BRANDED & PARC',
//   'SUP ABERT RELAC E CONTRAT CASH',
//   'SUP ADM CUSTODIA E CONTROL',
//   'SUP ADVANCED ANALYTICS',
//   'SUP AQUI DIG MONOLINERS',
//   'SUP AQUISICAO E GEST DE DADOS',
//   'SUP AQUISICAO VENDAS DIGITAIS',
//   'SUP ARQUIT NEGOC E SOLUCOES',
//   'SUP ARQUITETURA DE TECNOLOGIA',
//   'SUP ATD CARTOES E PCP CENTRAIS',
//   'SUP ATD CIV CONSIG E COMMAND',
//   'SUP ATEND EMP E SEGUROS',
//   'SUP ATENDIMENTO',
//   'SUP ATENDIMENTO DIGITAL',
//   'SUP ATIVOS E PRICING CART',
//   'SUP ATIVOS PJ',
//   'SUP ATRACAO SELECAO E TREIN',
//   'SUP AUD TI INSTIT E CREDITO',
//   'SUP AUTOM BANC SUPORTE A REDE',
//   'SUP BU CREDICARD',
//   'SUP BUILDERS HUB',
//   'SUP CAD VISAO CLIENT ARQ DADOS',
//   'SUP CANAIS E PAGAMENTOS DIGITA',
//   'SUP CANAIS INTERNOS',
//   'SUP CANAIS INTERNOS ITAUCRED',
//   'SUP CANAIS MOBILE E UX',
//   'SUP CARTOES VAREJO PJ',
//   'SUP COML ALTA RENDA E PJ',
//   'SUP COML CLIEN NCC DIG AFFIN',
//   'SUP COML CONSORCIO',
//   'SUP COML LOJAS INDEPENDENTES',
//   'SUP COML MIDDLE SPI',
//   'SUP COMPL VAREJO E TRABALHISTA',
//   'SUP COMUNICACAO & GROWTH',
//   'SUP COMUNIDADE VEICULOS',
//   'SUP CONSOL DIVULG CONTABEIS',
//   'SUP CONTR ATIVOS MN GARAN FORM',
//   'SUP CONTROLE E PLANEJ FINANC',
//   'SUP CORRETORA PF PJ E LIQUIDA',
//   'SUP CRM CC PF',
//   'SUP CRM PJ E NCC',
//   'SUP CUSTOMER SUCCESS',
//   'SUP CX',
//   'SUP DATA SCIENT ACE ANL DELIV',
//   'SUP DE COMUNICACAO',
//   'SUP DE CREDITO CARTOES',
//   'SUP DE CREDITO EMP2 E EMP3',
//   'SUP DE DISTRIBUICAO',
//   'SUP DE INSP E PREV FRAUDES',
//   'SUP DE NEGOCIOS CAMBIO',
//   'SUP DE OPERACOES AP',
//   'SUP DE PRODUTOS DIGITAIS',
//   'SUP DECISION SCIENCES',
//   'SUP DESENVOLV NEGOCIOS IAM',
//   'SUP DIGITAL CASH',
//   'SUP DIGITAL CRED REC E BUREAU',
//   'SUP ENG DE PRODUTOS E MIS EMP',
//   'SUP ENG DE PROJ DE INFRA DE TI',
//   'SUP ENGENHARIA E FERRAMENTAS',
//   'SUP ENGENHARIA PRODUTOS E RISC',
//   'SUP ESCRITORIO TRANSF DIGITAL',
//   'SUP ESTRATEGIA NEGOCIO E EXPAN',
//   'SUP EXP CLIENTE',
//   'SUP EXP COLABORADOR',
//   'SUP EXPERIENCIA DO CLIENTE',
//   'SUP FDAD E FCRESC',
//   'SUP FRANQ CENTRAIS ATENDIMENTO',
//   'SUP FRANQUIA E PROD CORRETORA',
//   'SUP FRANQUIAS E PRODUTOS LATAM',
//   'SUP GEST CLIENTE PERSONNALITE',
//   'SUP GEST COMERCIAL E GOVERNANC',
//   'SUP GESTAO CONT, ORCAM E METAS',
//   'SUP GESTAO DE ATIVOS',
//   'SUP GESTAO DE NEG QUANT E CONT',
//   'SUP GOV E PROJ CYBER SECURITY',
//   'SUP HUB DE RELAC MONOLINERS',
//   'SUP INVESTIMENTOS ULTRA',
//   'SUP JORNADA DOS CLIENTES',
//   'SUP JUR CORP IB SS PRODBR DIST',
//   'SUP JUR REESTRUT E COBRANCA',
//   'SUP JUR SOC CT PI IMB VEI CNSG',
//   'SUP JV LUIZACRED',
//   'SUP LIVEMKT E ENDOMKT',
//   'SUP LOGISTICA APLICADA',
//   'SUP MARKETING E DADOS',
//   'SUP MARTECH MIDIA ANALYTICS',
//   'SUP MERC PRODUTOS',
//   'SUP MESAS PF SOLUCOES DE CRED',
//   'SUP MKT CART IUPP ITI E SEG',
//   'SUP MKT PJ E MONOPRODUTOS',
//   'SUP MKT VAREJO PF',
//   'SUP MOD OPER AUTOM E ANALYTICS',
//   'SUP NEG BIG MARISA IPIRANGA',
//   'SUP NEG CLASSICOS NCC',
//   'SUP NEGOCIOS FIC',
//   'SUP NEGOCIOS REDE - CANAL ITAU',
//   'SUP NEGOCIOS VAREJO',
//   'SUP NUCLEO INTELIG DE CRED EMP',
//   'SUP OP CYBER SECURITY',
//   'SUP OPEN BANK E PAGTOS INST',
//   'SUP OPER ATIVOS E SERVICOS',
//   'SUP OPER CARTOES E BKO COB',
//   'SUP OPER CC PGTOS ARREC E CHS',
//   'SUP OPER COBRANCA PJ',
//   'SUP OPER LOGIST REDE',
//   'SUP OPER SEGUROS',
//   'SUP OPERACAO DATACENTER',
//   'SUP OPERACOES CIV',
//   'SUP OPERACOES INTERNAS PF',
//   'SUP OPERACOES TESOURARIA',
//   'SUP ORCAMEN E RESULT GERENCIAL',
//   'SUP OUVIDORIA PLANEJ E PROJET',
//   'SUP PAGAMENTOS DIGITAIS',
//   'SUP PARCERIAS E SOLUC CAPTURA',
//   'SUP PARTICIPANTES FIDELIZACAO',
//   'SUP PLAN ESTR FOLHA PAB CONSIG',
//   'SUP PLAN FIN TI AREAS SUPORTE',
//   'SUP PLANEJ CARTOES E BANDEIRA',
//   'SUP PLANEJ E SAUDE',
//   'SUP PLANEJAMENTO E CMI',
//   'SUP PLATAFORMA DE CREDITO',
//   'SUP PODER PUBLICO',
//   'SUP POL COBR E MESA ACORDO PJ',
//   'SUP POL CRED GAR E MICROCRED',
//   'SUP POL DE COBRANCA',
//   'SUP POL PLANEJ PROD DE COBR',
//   'SUP PORTFOLIO & ANALYTICS',
//   'SUP PREV FRAUDES NEG CIVS REDE',
//   'SUP PRICING E BANDEIRAS',
//   'SUP PROC OPER ACIVS',
//   'SUP PROD CASH MANAGEMENT',
//   'SUP PROD CREDITO E ANTECIPACAO',
//   'SUP PRODUCAO E GOVERNANCA TI',
//   'SUP PRODUTOS',
//   'SUP PRODUTOS AS A SERVICE',
//   'SUP PRODUTOS CASH MANAGEMENT',
//   'SUP PRODUTOS DADOS E UX ITI',
//   'SUP PRODUTOS FUNDACOES CARTOES',
//   'SUP PROJ & PROC ENGINEER',
//   'SUP PROJ PROC CRED E COBR',
//   'SUP PROP VALOR PEQUENO SELLER',
//   'SUP QUALIDADE E SATISFACAO',
//   'SUP RECURSOS DIRECIONADOS',
//   'SUP REG PERSONNALITE 18',
//   'SUP REMUNERACAO E BENEFICIOS',
//   'SUP RISCOS E OPERACOES ITI',
//   'SUP RO ATACADO',
//   'SUP RO VAREJO',
//   'SUP SALES TRADING & MIDDLE COS',
//   'SUP SEGURANCA DE AGENCIAS',
//   'SUP SEGUROS E ASSIST MEDICA',
//   'SUP SERVICOS CORPORATIVOS',
//   'SUP SERVICOS TEC & ANALYTICS',
//   'SUP SOLUC REDES E DATA CENTER',
//   'SUP SOLUCAO MEIOS DE PAGAMENTO',
//   'SUP SOLUCOES ANALITICAS VAREJO',
//   'SUP SOLUCOES DE SEGURANCA',
//   'SUP SOLUCOES E ANALYTICS',
//   'SUP SOLUCOES INFRA DIGITAL',
//   'SUP SUPORTE OPER ASSET',
//   'SUP TEC ASSISTIDOS OPER',
//   'SUP TEC CAMBIO E UNID EXTERNAS',
//   'SUP TEC CANAIS CROSS',
//   'SUP TEC CANAIS PF',
//   'SUP TEC CANAIS PJ E TESTES',
//   'SUP TEC CARTAO BRANCO',
//   'SUP TEC CARTOES',
//   'SUP TEC CARTOES PARCEIROS',
//   'SUP TEC CARTOES PF PJ',
//   'SUP TEC CASH MANAGEMENT',
//   'SUP TEC CONSIGNADO IC',
//   'SUP TEC CONSORCIO',
//   'SUP TEC CONTAS E TARIFAS',
//   'SUP TEC CONTRATAR E CANAIS',
//   'SUP TEC CREDITO',
//   'SUP TEC CREDITO IMOBILIARIO',
//   'SUP TEC CRM OFERTAS',
//   'SUP TEC CROSS E RECEBER',
//   'SUP TEC FRAUDE PLD AUTENTIC',
//   'SUP TEC GARANTIAS',
//   'SUP TEC INSTITUCIONAL PESSOAS',
//   'SUP TEC PAGAMENTOS',
//   'SUP TEC PROD SERV DE TESOURARI',
//   'SUP TEC RECUPERACAO CREDITO',
//   'SUP TEC SECURITIES SERVICES',
//   'SUP TEC SOL INVESTIMENTOS',
//   'SUP TEC VEICULOS',
//   'SUP TEC VENDER',
//   'SUP TRANSFORMATION OFFICE',
//   'SUP USER EXPERIENCE CIB CB',
//   'SUPERINTENDENCIA OMNICHANNEL',
//   'SUPT PAB REG 15-INT SP/SUL',
//   'SUPT TECNOLOGIA ITI',
// ];

export default function SignUp() {
  const { signUp } = useContext(AuthContext);

  const classGlobal = {
    backgroundColor: '#FF6200',
    minHeight: '100vh',
    paddingTop: '50px',
    paddingBottom: '50px'
  };

  const classCentral = {
    backgroundColor: '#fff',
    margin: 'auto',
    maxWidth: '43rem',
    minWidth: '0',
    paddingBottom: '50px',
  };

  const classContainerForm = {
    backgroundColor: '#fff',
  };

  const pNameLogin = {
    color: '#rgb(255 98 0)',
    fontSize: '4rem',
    padding: '4rem',
  };

  const handleSignUp = useCallback(
    async (values, formikHelper) => {
      try {
        await signUp(values);
      } catch (err) {
        let message;

        switch (err.response.status) {
          case 409:
            message = 'E-mail já cadastrado!';
            break;
          default:
            message = 'Erro ao realizar o cadastro!';
            break;
        }

        formikHelper.setFieldError('all', message);
      }
    },
    [signUp]
  );

  return (
    <Container fluid style={classGlobal}>
      <Container style={classCentral}>
        <Container style={classContainerForm}>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <img src="/itau_logo.png" style={{ width: '48px', height: '48px' }} alt="" />
            <p style={pNameLogin}>
              <b>Cadastro</b>
            </p>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
              name: '',
              functional: '',
              role: '',
              // community: '',
              // area: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Nome Completo é necessário'),
              email: Yup.string()
                .test('domain', 'Email Corporativo deve estar em um domínio válido', (email) => hasValidDomain(email))
                .required('Email Corporativo é necessário')
                .email('Email Corporativo inválido'),
              password: Yup.string()
                .required('Senha é necessária')
                .min(3, 'No mínimo 3 caracteres'),
              functional: Yup.string()
                .test('digits', 'Funcional deve ter 9 dígitos', (functional) => hasFunctionalDigits(functional))
                .test('type', 'Funcional deve ser numérico', (functional) => isNumeric(functional))
                .required('Funcional é necessária'),
              role: Yup.number()
                .typeError('Cargo deve ser escolhido')
                .required('Cargo é necessário'),
              // community: Yup.string()
              //   .typeError('Comunidade deve ser escolhida')
              //   .required('Comunidade é necessária'),
              // area: Yup.string()
              //   .typeError('Área deve ser escolhida')
              //   .required('Área é necessária'),
            })}
            onSubmit={handleSignUp}
          >
            {({
              values,
              handleChange,
              touched,
              errors,
              handleBlur,
              handleSubmit,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Nome Completo <span style={{ color: 'red ' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                      <div>
                        Seu E-mail Corporativo <span style={{ color: 'red ' }}>*</span>
                      </div>
                      <div
                        style={{ margin: '1rem', display: 'flex', flexDirection: 'column', fontSize: '1.2rem' }}
                      >
                        <span>
                          Os Emails Corporativos terminam em:
                        </span>
                        <span style={{ color: '#FF6200', fontWeight: 'bold' }}>
                          {FormattedDomains()}
                        </span>
                      </div>
                    </div>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Criar uma Senha <span style={{ color: 'red ' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="functional">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Funcional <span style={{ color: 'red ' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="functional"
                    value={values.functional}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.functional && !!errors.functional}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.functional}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="role">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Cargo <span style={{ color: 'red ' }}>*</span>
                  </Form.Label>
                  <br />
                  {DEFAULT_ROLES
                    //.sort((a, b) => {
                    //  return a[0].localeCompare(b[0]);
                    //})
                    .map(([value, id]) => {
                      return (
                        <>
                          <Form.Check
                            name="role"
                            inline
                            label={value}
                            type="radio"
                            value={id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.role && !!errors.role}
                            style={{ marginLeft: '1rem' }}
                          />
                          <br />
                        </>
                      );
                    })}
                  <Form.Control.Feedback type="invalid">
                    {errors.role}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Group controlId="community">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Comunidade <span style={{ color: 'red ' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="community"
                    value={values.community}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.community && !!errors.community}
                  >
                    <option></option>
                    {communityList.map(value => (
                      <option>{value}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.community}
                  </Form.Control.Feedback>
                </Form.Group> */}

                {/* <Form.Group controlId="area">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Área (busque por sua superintendência){' '}
                    <span style={{ color: 'red ' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="area"
                    value={values.area}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.area && !!errors.area}
                  >
                    <option></option>
                    {areaList.map(value => (
                      <option>{value}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.area}
                  </Form.Control.Feedback>
                </Form.Group> */}

                <div style={{ marginTop: '2rem', display: 'flow-root' }}>
                  <p style={{ fontSize: '1rem', float: 'right' }}>
                    Já é usuário? <Link to="/login">Login</Link>
                  </p>
                </div>

                <h5 style={{ color: 'red' }}>{errors.all}</h5>

                <Button
                  className="btn-extra-large"
                  text="Enviar"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </Container>
  );
}
