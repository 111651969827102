import React, {
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

export default function AuthRoute(props) {
  const { isPrivate = true, render, redirectPath, ...rest } = props;
  const { token } = useContext(AuthContext);
  const isSigned = useMemo(() => !!token, [token]);
  const renderRoute = useCallback(
    ({ location, ...restProps }) => {
      return isPrivate === isSigned ? (
        typeof render == 'function' ? (
          render({ location, ...restProps })
        ) : (
          render
        )
      ) : (
        <Redirect
          to={{
            pathname: isPrivate ? '/login' : redirectPath || '/assessments',
            state: { from: location },
          }}
        />
      );
    },
    [redirectPath, render, isPrivate, isSigned]
  );

  return <Route {...rest} render={renderRoute} />;
}
