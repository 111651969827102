import { getApiEndpoint } from '../shared/endpoint';

const UserApi = token => {
  const endpoint = getApiEndpoint();

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const getUser = async () => {
    return fetch(`${endpoint}/users/me`, {
      method: 'GET',
      ...requestOptions,
    }).then(resp => resp.json());
  };

  const updateUser = async (params) => {
    return fetch(
      `${endpoint}/users/me`,
      {
        method: 'PUT',
        body: JSON.stringify(params),
        ...requestOptions,
      }
    ).then(resp => resp.json());
  };

  return {
    getUser,
    updateUser,
  };
};

export default UserApi;
