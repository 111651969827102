import React, { useState } from 'react';
import '../assessment/AssessmentPicker.css';

import MyTests from '../components/MyTests';
import GeneralPopup from '../components/GeneralPopup';

const AssessmentPicker = ({ onPick, availableAssessments, linkTo }) => {
  const [preventClick, setPreventClick] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const assessmentsPopupStatus = ['finished', 'started', 'ready'];

  const handlePick = templateName => () => {
    if (
      !preventClick &&
      assessmentsPopupStatus.includes(availableAssessments[templateName].status)
    ) {
      setTemplateName(templateName);
      tooglePopup();
    } else if (!preventClick) {
      setPreventClick(true);
      onPick(templateName);
    } else {
      linkTo(`/assessments/${availableAssessments[templateName].id}`);
    }
  };

  const tooglePopup = () => setShowPopup(!showPopup);

  const textPopup = () => {
    if (availableAssessments[templateName].status === 'finished') {
      return `Você já fez o teste.`;
    } else {
      return `Deseja continuar de onde parou?`;
    }
  };

  const btnPopupGreen = () => {
    if (availableAssessments[templateName].status === 'finished') {
      return `Ir para meus testes`;
    } else {
      return `Sim`;
    }
  };

  const btnPopupWhite = () => {
    if (availableAssessments[templateName].status === 'finished') {
      return `Iniciar novo teste`;
    } else {
      return `Iniciar novo teste`;
    }
  };

  const actionButton = () => {
    if (availableAssessments[templateName].status === 'finished') {
      return () => linkTo('/assessments/');
    } else {
      return () =>
        linkTo(`/assessments/${availableAssessments[templateName].id}`);
    }
  };

  const actionButtonWhite = () => {
      return () => onPick(templateName);
  };

  return (
    <div className="container">
      <h1 className="h1-title">Boas vindas!</h1>
      <h1 className="h1-title-2">Vamos começar?</h1>
      <div>
        <div className="container-choices">
          {Object.keys(availableAssessments).map((templateName, i) => (
            <div className="choices" onClick={handlePick(templateName)} key={i}>
              <h3 className="choices-title">{availableAssessments[templateName].templateDescription}</h3>
            </div>
          ))}
          {showPopup && (
            <GeneralPopup
              title={'Mapa de Skills'}
              onClose={tooglePopup}
              linkTo={linkTo}
              text={textPopup()}
              btnPopupGreen={btnPopupGreen()}
              btnPopupWhite={btnPopupWhite()}
              actionButtonGreen={actionButton()}
              actionButtonWhite={actionButtonWhite()}
            />
          )}
          <MyTests linkTo={linkTo} />
        </div>
      </div>
    </div>
  );
};

export default AssessmentPicker;
