import React from "react";
import "./Button.css";

const Button = ({ className, ...props }) => (
  <button className={`Button btn ${className}`} {...props}>
    {props.text}
  </button>
);

export default Button;
