/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useContext, useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import api from '../../services/api';
import {
  Container,
  Card,
  Title2,
  TipsHighlight,
  TipsItem,
  TipsList,
  Labels,
  Label,
  LabelLine,
} from './styles';
import { getData } from './data';
import GraphLine from './graph-line';
import starIcon from '../../images/star-icon.png';
import infoIcon from '../../images/info-icon.png';

import './index.css';
import { AuthContext } from '../../contexts/AuthContext';

const round = v => (v % 1 > 0.5 ? Math.ceil(v) : Math.floor(v));

function Graph({ dimensions, dimensionsToCompare, isInExibitionMode }) {
  return (
    <div>
      <div style={{ margin: '20px 30px 0 0', position: 'relative' }}>
        <Labels>
          <Label>Novato</Label>
          <LabelLine />
          <Label>Iniciante</Label>
          <LabelLine />
          <Label>Experiente</Label>
          <LabelLine />
          <Label>Especialista</Label>
          <LabelLine />
          <Label>Mestre</Label>
        </Labels>
        <div style={{ paddingTop: '20px' }}>
          {Object.keys(dimensions).map((name, i) => {
            const dimension = dimensions[name];
            const dimensionToCompare = dimensionsToCompare && dimensionsToCompare[name]
            return (
              <GraphLine
                text={name}
                max={round(dimension.expected)}
                value={round(dimension.answered)}
                valueToCompare={round(dimensionToCompare?.answered) || 0}
                isInExibitionMode={isInExibitionMode}
                n={dimension.n}
                key={i}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ({ match }) => {
  const [data, setData] = useState({});
  const [dataReply, setDataReply] = useState(null);
  const [evaluatorName, setEvaluatorName] = useState('');
  const [exibitionMode, setExibitionMode] = useState('me')

  const [listRepliesToCompare, setListRepliesToCompare] = useState([])

  const { user } = useContext(AuthContext)

  const currentUserIsEvaluating = user.email === data?.replier?.email

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get(
        `/assessments/report/${match.params.id}/${match.params.token}`
      );
      setData(result.data);

      const resultListReplies = await api.get(`/assessments/replies/${match.params.token}`)
      const repliesMapped = resultListReplies.data.map(element =>
        ({
          id: element.id,
          value: `${element.evaluator} - ${new Date(element.evaluatedAt).toLocaleDateString()}`,
          score: element.score,
        })
      )
      setListRepliesToCompare(repliesMapped);
    };

    fetchData();
  }, [match.params.id, match.params.token]);

  if (!data.user || !data.scores) {
    return null;
  }

  const scores = data.scores;
  const labelsAvailables = data.target || Object.keys(scores);

  let label;
  let score;

  for (const currentLabel in scores) {
    if (!labelsAvailables.includes(currentLabel)) {
      continue;
    }
    const current = scores[currentLabel];

    if (!score || current.adhesion >= score.adhesion) {
      score = current;
      label = currentLabel;
    }
  }

  // eslint-disable-next-line no-unused-vars
  let labelToCompare;
  let scoreToCompare;

  for (const currentLabel in dataReply) {
    if (!labelsAvailables.includes(currentLabel)) {
      continue;
    }
    const current = dataReply[currentLabel];

    if (!scoreToCompare || current.adhesion >= scoreToCompare?.adhesion) {
      scoreToCompare = current;
      labelToCompare = currentLabel;
    }
  }

  const filteredDimensions = {};
  const filteredDimensions1 = {};
  const filteredDimensions2 = {};
  const filteredDimensionsWithoutWeight = {};

  const filteredDimensionsToCompare1 = {};
  const filteredDimensionsToCompare2 = {};

  Object.keys(score.dimensions)
    .filter(name => score.dimensions[name].weight)
    .sort((a, b) =>
      score.dimensions[a].adhesion > score.dimensions[b].adhesion ? -1 : 1
    )
    .forEach((name, i, o) => {
      score.dimensions[name].n = i + 1;
      filteredDimensions[name] = score.dimensions[name];

      if (i < o.length / 2) {
        filteredDimensions1[name] = score.dimensions[name];
        filteredDimensionsToCompare1[name] = scoreToCompare?.dimensions[name]
      } else {
        filteredDimensions2[name] = score.dimensions[name];
        filteredDimensionsToCompare2[name] = scoreToCompare?.dimensions[name]
      }
    });

  Object.keys(score.dimensions)
    .filter(name => !score.dimensions[name].weight)
    .sort((a, b) =>
      score.dimensions[a].answered > score.dimensions[b].answered ? -1 : 1
    )
    .forEach((name, i) => {
      filteredDimensionsWithoutWeight[name] = score.dimensions[name];
    });

  score.dimensions = filteredDimensions;
  score.dimensions1 = filteredDimensions1;
  score.dimensions2 = filteredDimensions2;
  score.dimensionsWithoutWeight = filteredDimensionsWithoutWeight;

  const totalStars = Object.keys(score.dimensions).length;
  const conqueredStars = Object.keys(score.dimensions).filter(name => {
    return (
      round(score.dimensions[name].answered) >=
      round(score.dimensions[name].expected)
    );
  }).length;
  const pcStars = Math.round((conqueredStars / totalStars) * 100);

  const onReplyChanged = item => {
    setEvaluatorName(item.value)
    setDataReply(item.score)
  }

  return (
    <Container>
      <p style={{ textAlign: 'center', fontSize: '100%', fontWeight: 'bold' }}>
        Respondido por: {(data?.replier?.name || user.name)}
      </p>

      <p style={{ textAlign: 'center', fontSize: '100%', fontWeight: 'bold' }}>
        Sobre: {data?.user?.name}
      </p>

      <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <strong style={{ marginRight: '1rem' }}>Modo de exibição:</strong>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
          <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
            <label htmlFor="me" style={{ fontSize: '120%', marginRight: '1rem', marginBottom: '0' }}>Avaliação</label>
            <input id='me' type="checkbox" checked={exibitionMode === 'me'} onChange={() => setExibitionMode('me')} />
          </div>
          {!currentUserIsEvaluating && (
            <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
              <label htmlFor="comparison" style={{ fontSize: '120%', marginRight: '1rem', marginBottom: '0' }}>Comparação</label>
              <input id='comparison' type="checkbox" checked={exibitionMode === 'comparison'} onChange={() => setExibitionMode('comparison')} />
            </div>
          )}
        </div>
      </div>
      <Card>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '0 1rem 2rem',
            borderBottom: '1px solid #ddd',
          }}
        >
          <div
            style={{
              flex: '0 50%',
              paddingRight: '3rem',
              borderRight: '1px solid #ddd',
            }}
          >
            {currentUserIsEvaluating ? (
              <>
                <p style={{ fontSize: '220%', margin: '0' }}>
                  <b>Olá, {data.replier.name}!</b>
                </p>
                <p style={{ fontSize: '130%', marginTop: '2rem' }}>
                  Você acaba de avaliar <b>{data.user.name}</b>. Após ambos terem finalizado as avaliações, é muito importante que alinhem um plano de desenvolvimento do liderado. O ideal é que você utilize o guia de conteúdos recomendados para apoiar a evolução da pessoa liderada
                </p>
              </>
            ) : (
              <>
                <p style={{ fontSize: '220%', margin: '0' }}>
                  <b>{data.user.name}!</b>
                </p>
                <span style={{ fontSize: '120%', color: '#888' }}>
                  {data.user.functional}
                  {data.user.community ? ` | ${data.user.community}` : ``}
                </span>
                <p style={{ fontSize: '130%', marginTop: '2rem' }}>
                  Você acaba de dar mais um passo na sua jornada de desenvolvimento.
                  Confira seu resultado e mergulhe nas ações recomendadas para que
                  você continue se desenvolvendo na Jornada de Produto Digital!
                  <a href="https://sway.office.com/p03ZmpUluJkfnZy5" target="blank">
                    <img
                      src={infoIcon}
                      alt=""
                      style={{ width: '14px', height: '14px', marginLeft: '3px' }}
                    />
                  </a>
                </p>
              </>
            )}
          </div>
          <div style={{ flex: '0 25%', paddingLeft: '3rem' }}>
            <p style={{ fontSize: '220%', margin: '0' }}>
              <b>Resultado</b>
            </p>
            <p style={{ fontSize: '130%', marginTop: '2rem' }}>
              É isso aí! {currentUserIsEvaluating ? data.user.name : 'Você' } já conquistou <b>{pcStars}%</b> dos conhecimentos
              necessários para seu momento de carreira atual!
            </p>
          </div>
          <div style={{ flex: '0 25%' }}>
            <GaugeChart
              id="gauge-chart4"
              nrOfLevels={5}
              arcPadding={0}
              cornerRadius={0}
              percent={pcStars / 100}
              arcWidth={0.6}
              animate={false}
              hideText={true}
              colors={['#ff6c6c', '#ff9b9b', '#ffdd70', '#ffc900', '#04d38d']}
              style={{ width: '95%', margin: '0' }}
            />
          </div>
        </div>

        {exibitionMode === 'comparison' && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'start',
              marginTop: '2rem',
              fontSize: '130%',
              alignItems: 'center',
            }}
          >
            {listRepliesToCompare.length > 0 ? (
              <>
                <p style={{ marginRight: '1%', marginBottom: 0 }}>Selecione uma avaliação e compare com o seu resultado:</p>
                <DropdownButton
                  as={ButtonGroup}
                  size="lg"
                  variant="secondary"
                  title="Selecione"
                >
                  {
                    listRepliesToCompare.map((item, index) => (
                      <Dropdown.Item key={index} onClick={() => onReplyChanged(item)}>{item.value}</Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </>
            ) : (
              <p style={{ marginRight: '1%', marginBottom: 0 }}>Nenhuma avaliação encontrada</p>
            )}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: '0 50%', padding: '1rem' }}>
            <Title2>Onde {currentUserIsEvaluating ? data.user.name : 'você' } mais se destaca:</Title2>
            <Graph
              label={label}
              dimensions={score.dimensions1}
              dimensionsToCompare={filteredDimensionsToCompare1}
              isInExibitionMode={exibitionMode === 'comparison'}
            />
          </div>
          <div style={{ flex: '0 50%', padding: '1rem' }}>
            <Title2>Onde {currentUserIsEvaluating ? data.user.name : 'você' } pode evoluir:</Title2>
            <Graph
              label={label}
              dimensions={score.dimensions2}
              dimensionsToCompare={filteredDimensionsToCompare2}
              isInExibitionMode={exibitionMode === 'comparison'}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingTop: '20px',
            borderBottom: '1px solid #ddd',
          }}
        >
          <div style={{ flex: '0 50%', padding: '1rem' }}>
            <div style={{ flex: '0 100%', padding: '0 2rem' }}>
              <div>
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      width: '32px',
                    }}
                  >
                    <img
                      src={starIcon}
                      alt=""
                      style={{ width: '22px', height: '22px' }}
                    />
                  </div>
                  <div
                    style={{
                      flex: '1',
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    Este indicador leva em consideração o que é esperado de você
                    no seu momento de carreira atual.
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: '8px' }}>
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      width: '32px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-block',
                        height: '16px',
                        width: '22px',
                        borderTopLeftRadius: '20px',
                        borderBottomLeftRadius: '20px',
                        backgroundColor: '#f06b23',
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      flex: '1',
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    Este indicador leva em consideração suas respostas no
                    preenchimento do mapa.
                  </div>
                </div>
              </div>
              {exibitionMode === 'comparison' && (
                <div style={{ paddingTop: '8px' }}>
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      width: '32px',
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-block',
                        height: '16px',
                        width: '22px',
                        borderTopLeftRadius: '20px',
                        borderBottomLeftRadius: '20px',
                        backgroundColor: '#004990',
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      flex: '1',
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    Este indicador leva em consideração as respostas esperadas pelo {evaluatorName}.
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
          <div style={{ flex: '0 50%', padding: '1rem', fontSize: '20%' }}>
            <p style={{ fontSize: '10px' }}>
              <b>Novato:</b> Você conhece o básico, ou talvez seja a primeira
              vez que esteja se conectando com este tema.
              <br />
              <b>Iniciante:</b> Você já ouviu falar algumas vezes, e até
              consegue participar de discussões sobre o tema, ainda como ouvinte
              e/ou aprendiz.
              <br />
              <b>Experiente:</b> Você já possui um bom domínio desta competência
              e é capaz de conduzir conversas e até práticas que a envolvam.
              <br />
              <b>Especialista:</b> Você é uma referência deste tema dentro do
              banco, e outros itubers te procuram para multiplicar esta
              competência internamente.
              <br />
              <b>Mestre:</b> Você é uma referência deste tema para o mercado,
              participando de fóruns e discussões com outros stakeholders fora
              do banco.
            </p>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: '0 100%', padding: '1rem' }}>
            <Title2>Como você pode começar a evoluir</Title2>
            <p>
              Sugerimos que você comece pelas habilidades que possui o maior gap
              de conhecimento. Confira as sugestões abaixo e separe agendas ao
              longo das próximas semanas para se dedicar a estas sugestões.
            </p>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            borderBottom: '1px solid #ddd',
            padding: '0 0 2rem',
          }}
        >
          {Object.keys(score.dimensions)
            .slice(-3)
            .map((name, i) => {
              const { n, answered } = score.dimensions[name];

              let level;

              // if (adhesion <= 0.4) {
              //   level = 0;
              // } else if (adhesion <= 0.8) {
              //   level = 1;
              // } else {
              //   level = 2;
              // }

              level = parseInt(answered) - 1;

              const data = getData(name, level);

              return (
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: '0 33%',
                    padding: '0 1rem 1rem 0',
                    borderRight: i !== 2 ? '1px solid #ddd' : '',
                  }}
                  key={i}
                >
                  <div
                    style={{
                      flex: '0',
                      position: 'relative',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'top',
                    }}
                  >
                    <span
                      style={{
                        color: '#eb7000',
                        textAlign: 'center',
                        width: '40px',
                        fontSize: '220%',
                        fontWeight: 'bold',
                        marginTop: '1.2rem',
                      }}
                    >
                      {n}
                    </span>
                  </div>
                  <div>
                    <h4
                      style={{
                        color: '#eb7000',
                        fontWeight: 'bold',
                        paddingTop: '1rem',
                      }}
                    >
                      {name}
                    </h4>
                    <h5
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {data.description}
                    </h5>
                    <ul
                      style={{
                        listStyleType: 'disc',
                        marginLeft: '3rem',
                        fontSize: '140%',
                      }}
                    >
                      {data.items}
                    </ul>
                  </div>
                </div>
              );
            })}
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flex: '0 100%', padding: '1rem 1rem 0' }}>
            <Title2>Dicas</Title2>
          </div>
          <div
            style={{
              flex: '0 33%',
              padding: '1rem',
              borderRight: '1px solid #ddd',
            }}
          >
            <TipsList>
              <TipsItem>
                Você pode fazer o mapa de skill{' '}
                <TipsHighlight>sempre quando achar necessário</TipsHighlight>.
                &#128516;
              </TipsItem>
            </TipsList>
          </div>
          <div
            style={{
              flex: '0 33%',
              padding: '1rem',
              borderRight: '1px solid #ddd',
            }}
          >
            <TipsList>
              <TipsItem>
                Além das orientações acima, busque outras fontes de conhecimento
                para se desenvolver (vídeos, artigos, etc) &#128218;
              </TipsItem>
            </TipsList>
          </div>
          <div style={{ flex: '0 33%', padding: '1rem' }}>
            <TipsList>
              <TipsItem>
                <a
                  href="https://login.microsoftonline.com/591669a0-183f-49a5-98f4-9aa0d0b63d81/oauth2/authorize?client_id=00000003-0000-0ff1-ce00-000000000000&response_mode=form_post&protectedtoken=true&response_type=code%20id_token&resource=00000003-0000-0ff1-ce00-000000000000&scope=openid&nonce=E67A3E8EB2B6D477774FDF894C78A42F4CD60C29112F274E-50BE0D6E431419A34417FD26C055A6B4712DB52C7350C4F05BFB1B382BBB70B4&redirect_uri=https%3A%2F%2Ficonectados.sharepoint.com%2F_forms%2Fdefault.aspx&state=OD0w&claims=%7B%22id_token%22%3A%7B%22xms_cc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D&wsucxt=1&cobrandid=11bd8083-87e0-41b5-bb78-0bc43c8a8e8a&client-request-id=0e043ea0-50ec-1000-bff7-4c3acb045d3f"
                  target="blank"
                >
                  <TipsHighlight>Não limite seu desenvolvimento</TipsHighlight>{' '}
                  às quatro compentências recomendadas. Verifique o que mais
                  você tem interesse e precisa se desenvolver, a depender da
                  natureza da sua atividade e área da qual faz parte! &#128161;
                </a>
              </TipsItem>
            </TipsList>
          </div>
        </div>
      </Card>
      {/* <pre>{JSON.stringify(data.scores[label], null, 2)}</pre> */}
    </Container>
  );
};
