import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './Assessment.css';
import "toastify-js/src/toastify.css"

import Question from './Question';
import Toastify from 'toastify-js';
import Instructions from './Instructions';
import Loading from '../components/Loading';

import api from '../services/api';

import { AuthContext } from '../contexts/AuthContext';

class Assessment extends Component {
  state = {
    assessment: this.props.assessment,
    current: 1,
    loading: false,
  };

  // assessment

  startAssessment = async (replyTo = null) => {
    const { assessmentApi } = this.props;
    const { assessment } = this.state;

    console.log(`start assessment to ${replyTo}`)
    try {
      await api.post(`assessments/${assessment.id}/start`, { replyTo });
    } catch (err) {
      let message;

      switch (err.response.status) {
        case 404:
          message = 'Colaborador não encontrado!';
          break;
        default:
          message = 'Erro ao iniciar o Mapa de Skills!';
          break;
      }

      return this.showToast(message);
    } finally {
      const newAssessment = await assessmentApi.startQuestion(assessment.id, 1);

      this.setState({ assessment: newAssessment });
    }
  };

  showToast = (message) => {
    Toastify({
      text: message,
      duration: 3000,
      close: true,
      gravity: "bottom",
      position: "center",
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "red"
      }
    }).showToast();
  }

  finishAssessment = async () => {
    const assessment = await this.props.assessmentApi.finishAssessment(
      this.state.assessment.id
    );

    this.setState({ assessment });
  };

  // questions

  nextQuestion = () =>
    this.state.assessment.questions.filter(question => !question.finishedAt)[0];

  nextQuestionNumber = () => {
    const next = this.currentQuestionNumber() + 1;

    if (this.state.assessment.questions[next - 1]) {
      return next;
    } else {
      return 1;
    }
  };

  currentQuestion = () =>
    this.state.assessment.questions[this.state.current - 1] ||
    this.nextQuestion();

  currentQuestionNumber = () => this.state.current;

  startQuestion = async questionNumber => {
    const { assessmentApi } = this.props;
    const { assessment } = this.state;

    if (questionNumber && questionNumber === this.currentQuestionNumber()) {
      return;
    }

    const newAssessment = await assessmentApi.startQuestion(
      assessment.id,
      questionNumber
    );

    this.setState({
      assessment: newAssessment,
      current: questionNumber,
    });
  };

  answerQuestion = async alternativeRef => {
    const { assessmentApi } = this.props;
    const { assessment } = this.state;

    const newAssessment = await assessmentApi.answerQuestion(
      assessment.id,
      this.currentQuestionNumber(),
      alternativeRef
    );

    this.setState({ assessment: newAssessment });
  };

  nextAction = async () => {
    this.setState({ loading: true });

    const next = this.nextQuestionNumber();

    if (next) {
      await this.startQuestion(next);
    }

    this.setState({ loading: false });
  };

  content = userToken => {
    const { assessment } = this.state;

    switch (assessment.status) {
      case 'ready':
        return (
          <Instructions
            startAssessment={this.startAssessment}
            linkTo={this.props.linkTo}
          />
        );
      case 'started':
        return (
          <>
            <div className="questions-list">
              {this.state.assessment.replyTo && (
                <p style={{ fontWeight: 'bold' }}>
                  Respondendo para: {this.state.assessment.replyTo.name} (
                  {this.state.assessment.replyTo.email})
                </p>
              )}
              <div className="label">Questões:</div>
              {this.state.assessment.questions.map((question, i) => {
                return (
                  <div
                    className={`question-link ${
                      this.state.current === i + 1 ? 'active' : null
                    } ${question.finishedAt ? 'answered' : null}`}
                    onClick={() => this.startQuestion(i + 1)}
                    key={i}
                  >
                    {i + 1}
                  </div>
                );
              })}
            </div>
            <Question
              question={this.currentQuestion()}
              onAnswer={this.answerQuestion}
              onNext={this.nextAction}
              onFinish={!this.nextQuestion() && this.finishAssessment}
              questionCount={assessment.questions.length}
              currentQuestionNumber={this.currentQuestionNumber()}
              hasNext={!!this.nextQuestionNumber()}
            />
          </>
        );
      case 'finished':
        this.props.history.replace(
          `/assessments/report/${assessment.id}/${userToken}`
        );
        return null;
      default:
        return <div />;
    }
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <div className="content-container">
          <Loading />
        </div>
      );
    }
    return (
      <AuthContext.Consumer>
        {props => {
          return (
            <div className="assessment-container">
              {this.content(props.user.userToken)}
            </div>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(Assessment);
