import React from 'react';
import '../components/GeneralPopup.css';
import closeIcon from '../icons/fechar.svg';

const GeneralPopup = ({
  title,
  text,
  text2,
  onClose,
  linkTo,
  btnPopupGreen,
  btnPopupWhite,
  actionButtonGreen,
  actionButtonWhite,
}) => {
  return (
    <>
      <div className="popup-overlay" onClick={onClose} />
      <div className="popup-container">
        <div className="popup-inside">
          <h1 className="popup-h1">
            {title}
            <img
              src={closeIcon}
              alt="Fechar"
              onClick={onClose}
              className="popup-img"
            />
          </h1>
          <p className="popup-p">{text}</p>
          {text2 ? <p className="popup-p">{text2}</p> : null}
          <div className="button-container">
            <button
              type="button"
              className="button-popup"
              onClick={actionButtonGreen}
            >
              {btnPopupGreen}
            </button>
            <button
              type="button"
              className="button-popup-white"
              onClick={actionButtonWhite}
            >
              {btnPopupWhite}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralPopup;
