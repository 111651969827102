import React from 'react';
import './MyTests.css';
import arrow from '../icons/icon-seta-direita.svg';
import 'react-router-dom';

const MyTests = ({ linkTo }) => {
  const linkToMyTests = () => {
    linkTo('/assessments/');
  };

  return (
    <div className="my-tests-container">
      <h3 className="my-tests-h3" onClick={linkToMyTests}>
        <img src={arrow} alt="arrow" className="my-tests-arrow" />
        <div>Ir para meus testes</div>
      </h3>
    </div>
  );
};

export default MyTests;
