import React from 'react';
import styled, { css } from 'styled-components';
import starIcon from '../../images/star-icon.png';

const LevelWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

const LevelLabelWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const NumberLabelWrapper = styled.div`
  flex: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const NumberLabel = styled.p`
  position: relative;
  padding: 0 0 0 0;
  margin: 0;
  font-size: 200%;
  font-weight: bold;
  width: 30px;
  text-align: center;
`;

const SkillLevelLabel = styled.p`
  position: relative;
  width: auto;
  padding: 0 15px 0 0;
  margin: 0;
  font-size: 120%;
  font-weight: bold;
`;

const SkillLevelWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 320px;
`;

const SkillLevelBar = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
  display: flex;
  border-radius: 20px;
  background-color: #bbb;
  padding: 8px;
`;

const SkillLevel = styled.div`
  position: relative;
  width: ${({ value }) => value * 20}%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #f06b23;
  height: 100%;

  ${({ value }) =>
    value === 5 &&
    css`
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    `}
`;

const ExpectedSkillLevel = styled.div`
  position: absolute;
  width: ${({ value }) => value * 20}%;
  height: 7px;
  border-top-left-radius: 10px;
  background-color: #004990;
  top: 0;
  transition: width 1s;

  ${({ value }) =>
    value === 5 &&
    css`
      border-top-right-radius: 10px;
    `}
`;

const SkillLevelMax = styled.div`
  position: absolute;
  left: ${({ value }) => value * 59.5}px;

  img {
    width: 24px;
    height: 24px;
    margin: -6px 0 0 0px;
  }

  ${({ value }) =>
    value === 5 &&
    css`
      left: 295px;
    `}
`;

export default ({ text, value, max, n, valueToCompare, isInExibitionMode }) => {
  return (
    <LevelWrapper>
      <NumberLabelWrapper>
        <NumberLabel>{n}</NumberLabel>
      </NumberLabelWrapper>
      <LevelLabelWrapper>
        <SkillLevelLabel>
          {text}
          {/* {text} {value} {max} */}
        </SkillLevelLabel>
      </LevelLabelWrapper>
      <SkillLevelWrapper>
        <SkillLevelBar>
          <div style={{ width: '100%', position: 'relative' }}>
            <SkillLevel value={value} />
            {isInExibitionMode && <ExpectedSkillLevel value={valueToCompare} />}
          </div>

          {!!max && (
            <SkillLevelMax value={max}>
              <img src={starIcon} alt="" />
            </SkillLevelMax>
          )}
        </SkillLevelBar>
      </SkillLevelWrapper>
    </LevelWrapper>
  );
};
