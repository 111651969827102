import React, { useContext, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import Button from '../components/Button';

import './AssessmentList.css';

const AssessmentList = ({ assessments, linkTo }) => {
  const { user } = useContext(AuthContext)
  const [filteredAssessments] = useState(assessments);

  const linkToNewAssessment = () => {
    linkTo('/assessments/new');
  };

  const linkToAssessment = id => () => {
    linkTo(`/assessments/${id}`);
  };

  const linkMessage = status => {
    switch (status) {
      case 'finished':
        return 'Ver Resultado';
      default:
        return 'Continuar';
    }
  };

  const NewAssessment = () => (
    <div className="assessment-list-cta">
      <Button
        id="start-new-assessment"
        disabled={false}
        onClick={linkToNewAssessment}
        className="btn-extra-large"
        text="Novo Teste"
      />
    </div>
  );

  const EmptyAssessment = () => (
    <div className="assessment-list-cta">
      <h1>Ops!</h1>
      <p>Parece que você ainda não preencheu seus skills. Vamos começar?</p>
      <Button
        id="start-new-assessment"
        disabled={false}
        onClick={linkToNewAssessment}
        className="btn-extra-large"
        text="Começar"
      />
    </div>
  );

  const formattedDate = rawDate => {
    const date = new Date(rawDate);
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    const mouth = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return ' em ' + day + '/' + mouth + '/' + year;
  };

  const assessmentCards = filteredAssessments.map((assessment, i, status) => {
    const statusDate = {
      started: assessment.startedAt,
      finished: assessment.finishedAt,
    };

    return (
      <div
        key={i}
        className="assessment-card"
        onClick={linkToAssessment(assessment.id)}
      >
        <div className="content">
          <div className="description">{assessment.template.description}</div>
          <div className="progress">
            <progress value={assessment.progress} max="100" />
            <div className="percent">{parseInt(assessment.progress || 0)}%</div>
          </div>
          <div className="status-container">
            <p className="status-p">
              {assessment.status !== 'ready'
                ? (assessment.replyUser || user.name) +
                  formattedDate(statusDate[assessment.status])
                : null}
            </p>
          </div>
        </div>
        <div className={`link ${assessment.status}`}>
          {linkMessage(assessment.status)}
        </div>
      </div>
    );
  });

  return (
    <div className="assessment-list-container">
      <div className="assessment-list-header">
        <h1>Jornada do Produto Digital</h1>
      </div>
      {assessmentCards.length === 0 && <EmptyAssessment />}
      <div className="assessment-list-card-container">{assessmentCards}</div>
      {assessmentCards.length > 0 && <NewAssessment />}
    </div>
  );
};

export default AssessmentList;
