export function getAppEndpoint() {
  return process.env.REACT_APP_ENDPOINT || 'http://skill.gama.desenvolvimento';
}

export function getApiEndpoint() {
  return process.env.REACT_APP_API_ENDPOINT || 'http://skill-api.gama.desenvolvimento';
}

export function getAuthEndpoint() {
  return process.env.REACT_APP_AUTH_ENDPOINT || 'http://skill.gama.desenvolvimento';
}
