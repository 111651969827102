import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import Assessment from './assessment/Assessment';
import AssessmentList from './assessment/AssessmentList';
import AssessmentPicker from './assessment/AssessmentPicker';
import AssessmentApi from './assessment/AssessmentApi';
import Session from './session/Session';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Loading from './components/Loading';
import { AuthContext } from './contexts/AuthContext';
import AuthRoute from './router/auth.router';
import SignUp from './session/SignUp';
import ForgotPassword from './session/ForgotPassword';
import ResetPassword from './session/ResetPassword';
import history from './services/history';
import ReportDetail from './assessment/ReportDetail';
import ProfileEdit from './profile/Edit';
import UserApi from './profile/UserApi';

class App extends Component {
  state = {
    user: undefined,
    availableAssessments: {},
    assessments: [],
    assessment: {},
    loading: true,
  };

  static contextType = AuthContext;

  buildAssessmentApi = () => {
    const { token } = this.context;
    return AssessmentApi(token);
  };

  buildUserApi = () => {
    const { token } = this.context;
    return UserApi(token);
  };

  load = () => (
    <div className="content-container">
      <Loading />
    </div>
  );

  linkTo = path => {
    this.setState({
      loading: true,
    });
    history.push(path);
  };

  availableAssessments = api => {
    api.availableAssessments().then(availableAssessmentsResponse => {
      this.setState({
        availableAssessments: availableAssessmentsResponse,
        loading: false,
      });
    });
  };

  listAssessments = api => {
    api.listAssessments().then(assessments => {
      this.setState({
        assessments,
        loading: false,
      });
    });
  };

  getAssessment = (api, id) => {
    (async () => {
      try {
        const assessment = await api.getAssessment(id);
        if (assessment) {
          this.setState({
            assessment,
            loading: false,
          });
        }
      } catch (err) {
        console.error(err, 'err');
      }
    })();
  };

  createAssessment = async templateName => {
    const assessment = await this.buildAssessmentApi().createAssessment(
      templateName
    );

    this.setState({
      assessment,
    });

    await this.linkTo(`/assessments/${assessment.id}`);
  };

  setUser = user => {
    this.setState({
      user,
    });
  };

  renderHome = api => ({ match }) => {
    api.listAssessments().then(assessments => {
      switch (true) {
        case assessments.length === 0:
          this.linkTo('/assessments/new');
          break;
        default:
          this.linkTo('/assessments');
          break;
      }
    });
    return this.load();
  };

  renderAssessments = api => () => {
    const { loading, assessments } = this.state;
    if (loading) {
      this.listAssessments(api);
      return this.load();
    } else {
      return <AssessmentList assessments={assessments} linkTo={this.linkTo} />;
    }
  };

  renderAssessmentPicker = api => () => {
    const { loading, availableAssessments } = this.state;
    if (loading) {
      this.availableAssessments(api);
      return this.load();
    } else {
      return (
        <AssessmentPicker
          availableAssessments={availableAssessments}
          onPick={this.createAssessment}
          linkTo={this.linkTo}
        />
      );
    }
  };

  renderAssessment = api => ({ match }) => {
    const { loading, assessment } = this.state;

    if (loading) {
      this.getAssessment(api, match.params.id);
      return this.load();
    }
    return (
      <>
        <Assessment
          assessment={assessment}
          assessmentApi={api}
          linkTo={this.linkTo}
        />
      </>
    );
  };

  getUser = (api) => {
    (async () => {
      try {
        const user = await api.getUser();

        if (user) {
          this.setState({
            user,
            loading: false,
          });
        }
      } catch (err) {
        console.error(err, 'err');
      }
    })();
  };

  renderProfileEdit = api => () => {
    const { loading, user } = this.state;

    if (loading) {
      this.getUser(api);
      return this.load();
    }
    return (
      <>
        <ProfileEdit
          user={user}
          linkTo={this.linkTo}
          api={api}
        />
      </>
    );
  };

  sessionProtected = () => {
    const api = this.buildAssessmentApi();
    const userApi = this.buildUserApi();
    const { user } = this.context;
    const isSigned = !!Object.keys(user).length;
    return (
      <div className="app-container">
        {isSigned && <Navbar linkTo={this.linkTo} />}
        <Switch>
          <AuthRoute path="/login" render={<Session />} isPrivate={false} />
          <AuthRoute path="/signup" render={<SignUp />} isPrivate={false} />
          <AuthRoute
            exact
            path="/assessments/new"
            render={this.renderAssessmentPicker(api)}
          />
          <Route
            exact
            path="/assessments/report/:id/:token"
            component={ReportDetail}
          />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/reset_password" component={ResetPassword} />
          <AuthRoute
            exact
            path="/assessments/:id"
            render={this.renderAssessment(api)}
          />
          <AuthRoute
            exact
            path="/assessments"
            render={this.renderAssessments(api)}
          />
          <AuthRoute exact path="/" render={this.renderHome(api)} />
          <AuthRoute
            exact
            path="/profile/edit"
            render={this.renderProfileEdit(userApi)}
          />
        </Switch>
        {isSigned && <Footer />}
      </div>
    );
  };

  render() {
    return <>{this.sessionProtected()}</>;
  }
}

export default App;
