import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-copy">
        Todos os direitos reservados © 2021 |Itaú Unibanco Holding S.A.
      </div>
    </div>
  );
};

export default Footer;
