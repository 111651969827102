import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Form, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';
import Button from '../components/Button';
import api from '../services/api';

export default function ForgotPassword() {
  const classGlobal = {
    backgroundColor: '#FF6200',
    height: '100vh',
    paddingTop: '50px',
  };
  const classCentral = {
    backgroundColor: '#fff',
    margin: 'auto',
    maxWidth: '43rem',
    minWidth: '0',
  };
  const classContainerForm = {
    backgroundColor: '#fff',
  };
  const pNameLogin = {
    color: '#rgb(255 98 0)',
    fontSize: '4rem',
    padding: '4rem',
  };
  const handleForgotPassword = useCallback(async (values, formikHelper) => {
    try {
      await api.post('/auth/forgot_password', values);
      formikHelper.setFieldError('ok', 'Verifique seu email!');
    } catch (err) {
      formikHelper.setFieldError('all', 'Erro ao recuperar a senha!');
    }
  }, []);
  return (
    <Container fluid style={classGlobal}>
      <Container style={classCentral}>
        <Container style={classContainerForm}>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <img src="/itau_logo.png" style={{ width: '48px', height: '48px' }} alt="" />
            <p style={pNameLogin}>
              <b>Esqueceu sua senha</b>
            </p>
          </div>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required('Email é necessário')
                .email('Email inválido'),
            })}
            onSubmit={handleForgotPassword}
          >
            {({
              values,
              handleChange,
              touched,
              errors,
              handleBlur,
              handleSubmit,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label style={{ fontSize: '1.5rem' }}>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <div style={{ marginTop: '2rem', display: 'flow-root' }}>
                  <p style={{ fontSize: '1rem', float: 'right' }}>
                    Já é usuário? <Link to="/login">Login</Link>
                  </p>
                </div>
                <div style={{ marginTop: '2rem', display: 'flow-root' }}>
                  <p style={{ fontSize: '1rem', float: 'right' }}>
                    Ainda não possui uma conta?{' '}
                    <Link to="/signup">Cadastre-se</Link>
                  </p>
                </div>
                <h5 style={{ color: 'green' }}>{errors.ok}</h5>
                <h5 style={{ color: 'red' }}>{errors.all}</h5>
                <Button
                  className="btn-extra-large"
                  text="Enviar"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </Container>
  );
}
