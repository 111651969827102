export const DEFAULT_ROLES = [
  ['ANL SR (Product Manager I, Product Analyst)', 1],
  ['COORD (Product Manager II, Chapter Lead)', 2],
  ['GTE (GPM)', 3],
  ['SUPTE (LNC, LNRT)', 4],
  ['DIRETOR(A) (BO, LNC)', 5],
  ['COORD (Product Manager II, SME)', 6],
  //['COORD (Product Manager II, SME) ESPECIALISTA II', 7],
  //['COORD (Product Manager II, SR PM)', 8],
  ['ANL PL (Product Analyst)', 9],
  ['CARREIRAS PARES (Data, Tech, etc...)', 10]
]

export const VALID_DOMAINS = [
  'itau-unibanco.com.br',
  'itaubba.com',
  'userede.com.br'
];

export const FUNCTIONAL_DIGITS = 9;

export const FormattedDomains = () => {
  let domains = VALID_DOMAINS.map((domain) => `@${domain}`)
  const lastDomain = domains.pop();

  return `${domains.join(', ')} ou ${lastDomain}`;
}

export function hasValidDomain(email) {
  const emailRegex = RegExp(`^[A-Za-z0-9._%+-]+@(${VALID_DOMAINS.join('|')})$`);

  return emailRegex.test(email);
}

export function hasFunctionalDigits(functional) {
  return functional && functional.length === FUNCTIONAL_DIGITS;
}

export function isNumeric(value) {
  return /^-?\d+$/.test(value)
}
