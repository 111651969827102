import React, { createContext, useState, useCallback, useEffect } from 'react';
import api from '../services/api';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const history = useHistory();
  const [user, setUser] = useState({});

  const [token, setToken] = useState(() => {
    const token = localStorage.getItem('@Gama:token');
    if (token) {
      api.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
    return token || '';
  });

  const changePassword = useCallback(
    async ({ password, password_confirmation, token }) => {
      const response = await api.post('/auth/reset_password', {
        password,
        password_confirmation,
        token,
      });
      const usr = {
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
        token: response.data.token,
      };
      setUser(usr);
      setToken(response.data.token);
      history.push('/assessments');
    },
    [history]
  );

  const login = useCallback(async usr => {
    const response = await api.post('/auth', usr);
    setUser({ ...response.data.user, token: response.data.token });
    setToken(response.data.token);
    localStorage.setItem('@Gama:token', response.data.token);
    api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
  }, []);

  const signUp = useCallback(async usr => {
    const response = await api.post('/auth/signup', usr);
    setUser({ ...response.data.user, token: response.data.token });
    setToken(response.data.token);

    localStorage.setItem('@Gama:token', response.data.token);
    api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
  }, []);

  const logOut = useCallback(() => {
    localStorage.removeItem('@Gama:token');
    setToken('');
    setUser({});
    window.location = '/login';
  }, []);

  useEffect(() => {
    if (token) {
      api.get('/auth/me').then(res => {
        setUser({ ...res.data, token: token });
      });
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{ user, login, token, logOut, signUp, changePassword }}
    >
      {children}
    </AuthContext.Provider>
  );
}
