import React from 'react';
import spinner from '../icons/spinner.svg';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      <p>Carregando...</p>
      <img className="loadingSpinner" src={spinner} alt="Loading..." />
    </div>
  );
};

export default Loading;
