import React, { useContext } from 'react';
import './Navbar.css';
import itauLogo from '../icons/itauLogo.png';
import { AuthContext } from '../contexts/AuthContext';

const Navbar = ({ linkTo }) => {
  const { logOut } = useContext(AuthContext);
  const toggleMenu = () => {
    const body = document.body;
    const menuButton = document.getElementById('mobile-menu-icon');
    const overlay = document.getElementsByClassName('mobile-menu-overlay')[0];

    body.classList.toggle('stop-scrolling');
    menuButton.classList.toggle('checked');
    overlay.classList.toggle('visible');
  };

  const linkToNewAssessment = () => {
    linkTo('/assessments/new');
    toggleMenu();
  };

  const linkToProfileEdit = () => {
    linkTo('/profile/edit');
    toggleMenu();
  };

  const menuItems = [
    {
      label: 'Perfil',
      to: '/profile/edit',
      onClick: linkToProfileEdit
    },
    {
      label: 'Sair',
      to: '/',
      onClick: logOut,
    },
  ];

  const MobileMenu = () => (
    <>
      <div className="mobile-menu">
        <input id="mobile-menu-icon" type="checkbox" onClick={toggleMenu} />
        <span />
        <span />
        <span />

        <ul className="menu">
          {menuItems.map(item => (
            <li className="menu-item" key={item.to}>
              <div className="menu-style" to={item.to} onClick={item.onClick}>
                {item.label}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mobile-menu-overlay" onClick={toggleMenu} />
    </>
  );

  const DesktopMenu = () => (
    <>
      {menuItems.map(item => (
        <div
          className="desktop-menu"
          to={item.to}
          key={item.to}
          onClick={item.onClick}
        >
          <span>{item.label}</span>
        </div>
      ))}
    </>
  );

  return (
    <div className="navbar">
      <div className="navbar-logo">
        <img
          src={itauLogo}
          alt="Menu principal"
          width="30rem"
          onClick={linkToNewAssessment}
        />
      </div>

      <MobileMenu />
      <DesktopMenu />
    </div>
  );
};

export default Navbar;
