import './Edit.css';
import Button from '../components/Button';

import * as Yup from 'yup';
import { Formik } from 'formik';
import Toastify from 'toastify-js';
import { Form } from 'react-bootstrap';
import React, { useCallback } from 'react';
import {
  isNumeric,
  DEFAULT_ROLES,
  hasValidDomain,
  FormattedDomains,
  hasFunctionalDigits,
} from './UserUtils';

function createToast(message, color) {
  Toastify({
    text: message,
    duration: 3000,
    close: true,
    gravity: "bottom",
    position: "center",
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: color
    }
  }).showToast();
}

const ProfileEdit = ({ user, linkTo, api }) => {

  const linkToAssessments = () => {
    linkTo('/assessments/');
  };

  const handleEditUser = useCallback(async (values, formikHelper) => {
    try {
      const response = await api.updateUser(values);

      if (response['error']) createToast(response['error'], 'red');
      else createToast('Usuário atualizado com sucesso', 'green')
    } catch (err) {
      formikHelper.setFieldError('all', err);
    }
  }, [api]);

  return (
    <div className="edit-profile-container">
      <div className="edit-profile-header">
        <h1>Perfil do usuário</h1>
      </div>
      <Formik
        initialValues={{
          name: user.name,
          email: user.email,
          functional: user.functional,
          role: user.role,
          community: user.community,
          area: user.area,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Nome Completo é necessário'),
          email: Yup.string()
            .test('domain', 'Email Corporativo deve estar em um domínio válido', (email) => hasValidDomain(email))
            .required('Email Corporativo é necessário')
            .email('Email Corporativo inválido'),
          functional: Yup.string()
            .test('digits', 'Funcional deve ter 9 dígitos', (functional) => hasFunctionalDigits(functional))
            .test('type', 'Funcional deve ser numérico', (functional) => isNumeric(functional))
            .required('Funcional é necessária'),
          role: Yup.number()
            .typeError('Cargo deve ser escolhido')
            .required('Cargo é necessário'),
        })}
        onSubmit={handleEditUser}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label style={{ fontSize: '1.5rem' }}>
                Nome Completo <span style={{ color: 'red ' }}>*</span>
              </Form.Label>
              <Form.Control
                name="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={touched.name && !!errors.name}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label style={{ fontSize: '1.5rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                  <div>
                    Seu E-mail Corporativo <span style={{ color: 'red ' }}>*</span>
                  </div>
                  <div
                    style={{ margin: '1rem', display: 'flex', flexDirection: 'column', fontSize: '1.2rem' }}
                  >
                    <span>
                      Os Emails Corporativos terminam em:
                    </span>
                    <span style={{ color: '#FF6200', fontWeight: 'bold' }}>
                      {FormattedDomains()}
                    </span>
                  </div>
                </div>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={touched.email && !!errors.email}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="functional">
              <Form.Label style={{ fontSize: '1.5rem' }}>
                Funcional <span style={{ color: 'red ' }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="functional"
                value={values.functional}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.functional && !!errors.functional}
              />
              <Form.Control.Feedback type="invalid">
                {errors.functional}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label style={{ fontSize: '1.5rem' }}>
                Cargo <span style={{ color: 'red ' }}>*</span>
              </Form.Label>
              <br />
              {DEFAULT_ROLES.map(([value, id]) => {
                return (
                  <>
                    <Form.Check
                      name="role"
                      inline
                      label={value}
                      type="radio"
                      value={id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={id === values.role}
                      isInvalid={touched.role && !!errors.role}
                      style={{ marginLeft: '1rem' }}
                    />
                    <br />
                  </>
                );
              })}
              <Form.Control.Feedback type="invalid">
                {errors.role}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="functional">
              <Form.Label style={{ fontSize: '1.5rem' }}>
                Comunidade
              </Form.Label>
              <Form.Control
                type="text"
                name="community"
                value={values.community}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.community && !!errors.community}
              />
              <Form.Control.Feedback type="invalid">
                {errors.community}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="functional">
              <Form.Label style={{ fontSize: '1.5rem' }}>
                Área
              </Form.Label>
              <Form.Control
                type="text"
                name="area"
                value={values.area}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.area && !!errors.area}
              />
              <Form.Control.Feedback type="invalid">
                {errors.area}
              </Form.Control.Feedback>
            </Form.Group>

            <div className='edit-profile-buttons'>
              <Button
                className="btn-extra-large-white"
                text="Cancelar"
                onClick={linkToAssessments}
              />
              <Button
                className="btn-extra-large"
                text="Enviar"
                type="submit"
              />
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProfileEdit;
