import React, { Component, createRef } from 'react';
import './Question.css';
import Button from '../components/Button';
import Alternative from './Alternative';
import Loading from 'gama-academy-design-system/dist/Loading';

class Question extends Component {
  state = {
    selectedAlternativeRef: this.props.question.selectedAlternative || '',
    loading: false,
    focusRef: createRef(),
    prevCurrentQuestionNumber: this.props.currentQuestionNumber,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.currentQuestionNumber !== state.prevCurrentQuestionNumber) {
      return {
        selectedAlternativeRef: props.question.selectedAlternative,
        prevCurrentQuestionNumber: props.currentQuestionNumber,
      };
    }
    return null;
  }

  // componentWillReceiveProps = (props) => {
  //   const { selectedAlternative } = props.question;

  //   this.setState({
  //     selectedAlternativeRef: selectedAlternative || '',
  //   });
  // };

  changeSelectedAlternative = async alternativeRef => {
    if (alternativeRef === this.state.selectedAlternativeRef) {
      return;
    }

    this.state.focusRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    this.setState(
      {
        selectedAlternativeRef: alternativeRef,
      },
      async () => {
        await this.sendAnswer();
      }
    );
  };

  sendAnswer = async () => {
    const { onAnswer } = this.props;
    const { selectedAlternativeRef } = this.state;

    if (onAnswer) {
      await onAnswer(selectedAlternativeRef);
    }
  };

  next = async () => {
    const { onNext } = this.props;

    this.setState({ loading: true });

    await onNext();

    this.setState({
      loading: false,
    });
  };

  listAlternatives = () => {
    const { selectedAlternativeRef } = this.state;

    return this.props.question.alternatives.map((alternative, i) => (
      <Alternative
        alternative={alternative}
        onSelected={this.changeSelectedAlternative}
        selected={alternative.reference === selectedAlternativeRef}
        key={i}
      />
    ));
  };

  actionBar = () => {
    const { loading } = this.state;

    return (
      <div className="action-bar">
        {this.props.hasNext && (
          <Button
            className="btn-medium caipirinha"
            onClick={this.next}
            text="Avançar"
            disabled={loading}
          />
        )}
        {this.props.onFinish && (
          <Button
            id="next-btn"
            className="btn-medium caipirinha"
            onClick={this.props.onFinish}
            text="Finalizar"
            disabled={loading}
          />
        )}
      </div>
    );
  };

  render() {
    const { question } = this.props;
    const { focusRef, loading } = this.state;

    return (
      <div className="question-container">
        {loading && <Loading />}
        <div className="question-container-img">
          {question.imageUrl && (
            <img className="question-img" src={question.imageUrl} alt="" />
          )}
        </div>
        {question.code && (
          <pre>
            <code className="language-javascript">{question.code}</code>
          </pre>
        )}
        <div>
          <h1 className="question-title">
            <div dangerouslySetInnerHTML={{ __html: question.text }} />
          </h1>
          <div>
            <ul>{this.listAlternatives()}</ul>
          </div>
          <div ref={focusRef}>{this.actionBar()}</div>
        </div>
      </div>
    );
  }
}

export default Question;
