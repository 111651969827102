import React, { useCallback, useMemo, useContext } from 'react';
import { Formik } from 'formik';
import { Form, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import * as Yup from 'yup';
import Button from '../components/Button';
import { AuthContext } from '../contexts/AuthContext';

export default function ResetPassword() {
  const location = useLocation();
  const params = useMemo(() => qs.parse(location.search.replace('?', '')), [
    location.search,
  ]);
  const { changePassword } = useContext(AuthContext);
  const classGlobal = {
    backgroundColor: '#FF6200',
    height: '100vh',
    paddingTop: '50px',
  };
  const classCentral = {
    backgroundColor: '#fff',
    margin: 'auto',
    maxWidth: '43rem',
    minWidth: '0',
  };
  const classContainerForm = {
    backgroundColor: '#fff',
  };
  const pNameLogin = {
    color: '#rgb(255 98 0)',
    fontSize: '4rem',
    padding: '4rem',
  };
  const handleResetPassword = useCallback(
    async (values, formikHelper) => {
      try {
        const token = params.token;
        await changePassword({
          password: values.password,
          password_confirmation: values.password_confirmation,
          token,
        });
      } catch (err) {
        let message;

        switch (err.response.status) {
          case 406:
            message = 'Token inválido';
            break;
          case 428:
            message = 'Token expirado';
            break;
          default:
            message = 'Erro ao realizar a recuperação de senha!';
            break;
        }

        formikHelper.setFieldError('all', message);
      }
    },
    [params, changePassword]
  );
  return (
    <Container fluid style={classGlobal}>
      <Container style={classCentral}>
        <Container style={classContainerForm}>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <img alt="Gama Academy" src="/itau_logo.png" style={{ width: '48px', height: '48px' }}/>
            <p style={pNameLogin}>
              <b>Recuperar senha</b>
            </p>
          </div>
          <Formik
            initialValues={{ password: '', password_confirmation: '' }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required('Senha é necessária')
                .min(3, 'No mínimo 3 caracteres'),
              password_confirmation: Yup.string().oneOf(
                [Yup.ref('password')],
                'Confirmação Incorreta'
              ),
            })}
            onSubmit={handleResetPassword}
          >
            {({
              values,
              handleChange,
              touched,
              errors,
              handleBlur,
              handleSubmit,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="password">
                  <Form.Label style={{ fontSize: '1.5rem' }}>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label style={{ fontSize: '1.5rem' }}>
                    Confirmar Senha
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password_confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange}
                    isInvalid={
                      touched.password_confirmation &&
                      !!errors.password_confirmation
                    }
                    onBlur={handleBlur}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password_confirmation}
                  </Form.Control.Feedback>
                </Form.Group>
                <div style={{ marginTop: '2rem', display: 'flow-root' }}></div>

                <h5 style={{ color: 'red' }}>{errors.all}</h5>

                <Button
                  className="btn-extra-large"
                  text="Enviar"
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </Container>
  );
}
