import styled from 'styled-components';

export const Container = styled.div`
  background-color: #efe8e4;
  width: 100%;
  padding: 2rem 4rem 4rem 4rem;
`;

export const Card = styled.div`
  background-color: #fff;
  padding: 3rem;
  border-radius: 2px;
`;

export const Title = styled.h2`
  font-weight: bold;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export const Title2 = styled.h3`
  font-weight: bold;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
`;

export const ProfileLabel = styled.h5`
  color: #eb7000;
  font-weight: bold;
`;

export const ProfileValue = styled.h4``;

export const TipsHighlight = styled.b`
  color: #004990;
  font-weight: bold;
`;

export const TipsItem = styled.li`
  font-size: 140%;
`;

export const TipsList = styled.ul`
  list-style-type: disc;
  flex: 0 50%;
  padding: 0 3rem;
`;

export const Labels = styled.div`
  text-align: right;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -10px;
  padding-right: 10px;
`;

export const Label = styled.div`
  display: inline-block;
  width: 60px;
  font-size: 90%;
  text-align: center;
  height: 100%;
`;

export const LabelLine = styled.span`
  border-left: 1px dashed #888;
  height: 100%;
  position: absolute;
  transform: translateY(20px);
`;
