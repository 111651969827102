import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import './Instructions.css';
import checkIcon from '../icons/check.svg';
import Button from '../components/Button';

const listStyle = {
  listStyleImage: `url(${checkIcon})`,
  paddingLeft: '1rem',
};

const formStyle = {
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const Instructions = ({ startAssessment, linkTo }) => {
  const [replyTo, setReplyTo] = useState(null);

  return (
    <div className="instructions-container">
      <h1 className="instructions-title">
        Boas-vindas ao Mapa de Skills
        <br />
        Sua ferramenta de autodesenvolvimento na Gestão de Produtos Digitais
      </h1>
      <p style={{ textAlign: 'left' }}>
        <b>Olá,</b>
        <br />
        <br />
        Esta ferramenta ajudará você a evoluir seus conhecimentos na disciplina
        de Gestão de Produtos Digitais. Ela pode ser usada por qualquer
        profissional do Itaú Unibanco que atua – ou tem interesse em atuar – na
        gestão de um produto digital. As perguntas foram criadas pensando nas
        competências necessárias para gerir produtos digitais.{' '}
        <a href="https://sway.office.com/p03ZmpUluJkfnZy5" target="blank">
          Saiba mais sobre elas aqui
        </a>{' '}
        (ambiente logado).
        <br />
        <br />
      </p>
      <ul className="instructions-list">
        <li style={listStyle}>
          Responda com sinceridade e transparência. As informações serão usadas
          para sugerir ações de desenvolvimento e ajudar em seus próximos passos
          de carreira;
        </li>
        <li style={listStyle}>
          Considere seu conhecimento de vida e não limite as respostas pensando
          apenas nas suas atividades atuais;
        </li>
        <li style={listStyle}>Reserve 1h para responder o mapa;</li>
        <li style={listStyle}>
          O feedback com a trilha de desenvolvimento sugerida será liberado
          assim que o mapa for finalizado;
        </li>
        <li style={listStyle}>
          Aproveite para tratar um plano de desenvolvimento em PDI;
        </li>
        <li style={listStyle}>
          Lembre-se que você é protagonista e principal responsável pela sua
          carreira, mas deve contar com o apoio de sua liderança;
        </li>
        <li style={listStyle}>
          Algumas questões acompanham breve explicação acerca dos termos
          técnicos presentes no texto. Caso não tenha familiaridade ou acredite
          que tais termos não se aplicam para sua realidade, marque a primeira
          alternativa;
        </li>
        <li style={listStyle}>
          Considere-se “referência de mercado” (o termo aparecerá em algumas
          alternativas) se a sua presença for requisita para multiplicação e
          discussão do respectivo conhecimento em eventos e encontros externos
          ao Itaú Unibanco (exemplo: podcasts, palestras, workshops, aulas,
          etc);
        </li>
      </ul>
      <p style={formStyle}>
        Caso queira fazer o teste para outro colaborador, insira abaixo o email
        do colaborador:
      </p>
      <div style={formStyle}>
        <div style={{ width: '60%' }}>
          <Form.Control
            type="email"
            onChange={e => setReplyTo(e.target.value)}
          />
        </div>
      </div>
      <div className="btns-container">
        <Button
          className="btn-extra-large"
          text="Continuar"
          onClick={() => startAssessment(replyTo)}
        />
      </div>
    </div>
  );
};

export default Instructions;
